<template>
  <div>
    <div
      v-for="(item, index) in orderItems"
      :key="item.data.id"
      :class="index < orderItems.length - 1 ? `border-bottom pb-1 mb-1` : ``"
      :set="[(p = getProduct(item))]"
    >
      <div class="border-bottom p-1">
        <ul v-if="!item.isSkipProcessor" class="list-unstyled multi-steps">
          <li
            v-for="step in item.deliverable_status"
            :key="step.id"
            class="step"
            :class="
              step.order_number === p.status &&
              step.order_number !==
                (step.max_steps || item.deliverable_status.length)
                ? 'is-active'
                : step.order_number > p.status
                ? 'after-step'
                : 'before-step'
            "
          >
            <div class="step-icon">
              <feather-icon
                v-if="step.order_number < item.data.status"
                icon="CheckIcon"
                size="20"
                class="mr-0"
                style="top: 6px; left: 36%"
              />
              <span
                v-if="step.order_number >= item.data.status"
                class
                style="font-weight: 700"
                >{{ step.order_number }}</span
              >
            </div>
            <!-- <span class="d-block">{{ $t(`vn_translate.${step.name}`) }}</span> -->
            <div style="margin-top: 40px">
              <span class="d-block">
                {{
                  currLanguage == "en"
                    ? getTitleDeliveryStatusEn(
                        step.product_delivery_states_multilanguage
                      ) || step.name
                    : step.name
                }}
              </span>
              <small class="d-block text-secondary" v-if="step.date">{{
                appUtils.formatDateTime(step.date)
              }}</small>
            </div>
          </li>
        </ul>
      </div>
      <div class="p-1">
        <h5
          class="border-bottom pb-25 d-flex justify-content-between align-items-center"
        >
          <span class="mr-50">
            {{
              currLanguage == "en"
                ? getProductNameEn(item.product_name_multilanguages) ||
                  item.data.product_name
                : item.data.product_name
            }}
          </span>
          <span class="ml-auto text-nowrap">
            <span class="mr-50 font-weight-bold"
              >{{ $t("lbl_test_price") }}:</span
            >
            <span class="text-primary font-weight-bold">{{
              appUtils.numberFormat(item.data.total_amount, "VNĐ")
            }}</span>
          </span>
        </h5>
        <b-row class="align-items-center">
          <b-col cols="12" :md="8">
            <b-row no-gutters class="align-items-center">
              <b-col cols="12" :md="3">
                <div
                  class="position-relative bg-white border rounded text-center p-25"
                >
                  <b-img
                    style="max-height: 120px"
                    :src="item.data.primary_image"
                    :alt="
                      currLanguage == 'en'
                        ? getProductNameEn(item.product_name_multilanguages)
                        : item.data.product_name
                    "
                    fluid
                  />
                </div>
              </b-col>
              <b-col cols="12" :md="9">
                <div class="pl-50" style="font-size: 12px">
                  <div>
                    <span class="mr-50 font-weight-bold"
                      >{{ $t("lbl_price") }}:</span
                    >
                    <span class="text-primary">
                      {{ appUtils.numberFormat(item.data.price, "VNĐ") }}
                    </span>
                    <span class="mx-25">/</span>
                    <span>
                      {{ item.data.product_variant_unit_name }}
                      <b-badge
                        v-if="isAppliedCampaign(item)"
                        class="coupon"
                        variant="warning"
                      >
                        <span v-if="item.data.campaign_info.discount_percentage"
                          >-{{
                            item.data.campaign_info.discount_percentage
                          }}%</span
                        >
                        <span v-else>
                          -{{
                            appUtils.numberFormat(
                              item.data.campaign_info.discount_amount,
                              "VND"
                            )
                          }}
                        </span>
                      </b-badge>
                    </span>
                  </div>
                  <div v-if="item.data.other_expenses">
                    <div class="font-weight-bold">
                      {{ $t("lbl_other_costs_") }}
                    </div>
                    <ul class="list-unstyled mb-0">
                      <li
                        v-for="(expense, idx) in item.data.other_expenses"
                        :key="idx"
                      >
                        <div>
                          <span v-if="expense.name" class="mr-50"
                            >- {{ expense.name }}:</span
                          >
                          <span class="text-primary">{{
                            appUtils.numberFormat(expense.value, "VNĐ")
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-if="!item.isSkipProcessor" class="d-flex items-center">
                    <span class="mr-50 font-weight-bold"
                      >{{ $t("lbl_processor.lbl_return_date") }}:</span
                    >
                    <span class="text-primary">
                      {{
                        formatDate(item.data.return_date, "HH:mm DD/MM/YYYY")
                      }}
                    </span>
                    <WarningIcon
                      class="margin"
                      v-if="
                        getResultDateInfor(
                          item.data.return_date,
                          item.data.order_processor &&
                            item.data.order_processor.status
                        ).isShowBadge
                      "
                      :fill="getResultDateInfor(item.data.return_date).variant"
                      :title="getResultDateInfor(item.data.return_date).tooltip"
                      v-b-tooltip.top
                    ></WarningIcon>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="!item.isSkipProcessor" cols="12" :md="4">
            <span
              v-b-tooltip.bottom
              :title="
                $t(
                  isCancelOrder
                    ? 'lbl_the_order_has_been_canceled__you_cannot_create_a_result_paper'
                    : ''
                )
              "
            >
              <!-- :text="$t(`vn_translate.${getStepName(item)}`)  || $t('lbl_change_step')" -->
              <b-dropdown
                :disabled="isCancelOrder"
                split
                split-variant="outline-primary px-50"
                variant="primary"
                :text="getStepName(item) || $t('lbl_change_step')"
                split-class="dropdown"
                menu-class="w-100"
                ref="dropdownState"
                @click="handleDropdownClicked(index)"
              >
                <b-dropdown-item
                  v-for="status in item.deliverable_status"
                  :key="status.id"
                  @click="changeStatus(item, status)"
                >
                  <!-- <span>{{ $t(`vn_translate.${status.name}`) }}</span> -->
                  <span>
                    {{
                      currLanguage == "en"
                        ? getTitleDeliveryStatusEn(
                            status.product_delivery_states_multilanguage
                          )
                        : status.name
                    }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </b-col>
        </b-row>
        <b-card
          v-if="
            item.order_item_form_attachments &&
            item.order_item_form_attachments.length
          "
          class="m-0 mt-1 cs-card"
        >
          <div
            class="flex items-center"
            style="gap: 8px"
            v-for="(
              attachmentRequired, index
            ) in item.order_item_form_attachments"
            :key="index"
          >
            <h5 class="m-0">{{ attachmentRequired.title }}:</h5>
            <b-link
              :href="attachmentRequired.url"
              v-if="attachmentRequired && attachmentRequired.url"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9C11 8.73478 10.8946 8.48043 10.7071 8.29289C10.5196 8.10536 10.2652 8 10 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9ZM20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM15 16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16Z"
                  fill="#20419b"
                />
              </svg>
              {{ getFileName(attachmentRequired.url) }}
            </b-link>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WarningIcon from "@/components/WarningIcon.vue";
import { ORDER_FORM_PROCESSOR_STATUS } from "@/utils/constant";
import store from "@/store";

export default {
  name: "OrderInfomation",
  components: {
    WarningIcon,
  },
  data() {
    return {
      appUtils,
      listItems: [],
      ORDER_FORM_PROCESSOR_STATUS,
      currLanguage: store.state.language.currLanguage,
    };
  },

  props: {
    orderDetail: {
      type: Object,
      default: () => {},
    },
    orderItems: {
      type: Array,
      default: () => [],
    },
    isCancelOrder: Boolean,
  },
  watch: {
    orderItems: {
      handler(data) {
        if (data?.length) {
          this.listItems = [...data];

          this.listItems.forEach((order) =>
            this.$set(order, "stepSelected", null)
          );
        }
      },
      deep: true,
    },
    "$store.state.language.currLanguage": function () {
      this.currLanguage = this.$store.state.language.currLanguage;
    },
  },
  methods: {
    isAppliedCampaign(item) {
      return (
        item?.data?.campaign_info?.discount_percentage ||
        item?.data?.campaign_info?.discount_amount
      );
    },
    getProduct(data) {
      return data?.data;
    },
    getStepName(item) {
      if (item.stepSelected) return item.stepSelected?.name;
      else if (this.currLanguage == "en") {
        return item.deliverable_status
          ?.find((stt) => stt.order_number == item.data.status)
          ?.product_delivery_states_multilanguage.find(
            (elm) => elm.language == this.currLanguage
          )?.title;
      } else
        return item.deliverable_status?.find(
          (stt) => stt.order_number == item.data.status
        )?.name;
    },
    async changeStatus(item, status) {
      item.stepSelected = status;
      let params = {
        id: item.data.id,
        max_steps: item.deliverable_status?.length,
        order_number: status.order_number,
      };
      try {
        this.$emit("setIsLoading", true);
        await this.$rf
          .getRequest("CommonRequest")
          .postUpdateStatusOrder(params);
        this.$emit("changeStatus", {
          order_item_id: item.data.id,
          status: status,
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("notification.lbl_update_order_status_success"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.$emit("refresh", { id: this.orderDetail.id });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("notification.lbl_update_order_status_failure"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    handleDropdownClicked(index) {
      const dropdown = this.$refs.dropdownState[index];
      setTimeout(() => {
        if (dropdown.visible) {
          dropdown.hide();
        } else dropdown.show();
      }, 0);
    },
    getTotalPrice(product) {
      let totalRaw = 0;
      product?.other_expenses?.map((item) => {
        totalRaw += item?.value;
      });
      const total = appUtils.numberFormat(
        product.price * product.qty + (totalRaw || 0),
        "VNĐ"
      );
      return total;
    },
    getFileName(url) {
      if (!url) return "";
      const fileName = url.substring(url.lastIndexOf("/") + 1);
      return fileName;
    },
    formatDate(time, format = "DD/MM/YYYY") {
      if (!time) return "";
      const date = moment.utc(time).format();
      return moment.utc(date).local().format(format);
    },
    getResultDateInfor(return_date, order_processor_status = 0) {
      const time = return_date - Date.now();
      if (
        !return_date ||
        [
          ORDER_FORM_PROCESSOR_STATUS.CANCEL,
          ORDER_FORM_PROCESSOR_STATUS.COMPLETE,
          ORDER_FORM_PROCESSOR_STATUS.SUPPLIER_CANCEL,
        ].includes(order_processor_status)
      ) {
        return {
          isShowBadge: false,
        };
      } else if (time < -86400000) {
        return {
          isShowBadge: true,
          variant: "red",
          tooltip: this.$t("lbl_the_deadline_for_returning_results_has_passed"),
        };
      } else if (time < 0) {
        return {
          isShowBadge: true,
          variant: "red",
          tooltip: this.$t("lbl_results_are_due_today"),
        };
      } else if (time < 86400000 * 3) {
        return {
          isShowBadge: true,
          variant: "#ffc107",
          tooltip: this.$t(
            "lbl_there_is_1_day_left_until_the_appointment_to_return_the_results",
            [Math.ceil(time / 86400000)]
          ),
        };
      } else {
        return {
          isShowBadge: false,
        };
      }
    },
    getTitleDeliveryStatusEn(arr) {
      return arr?.find((elm) => elm.language == "en")?.title;
    },
    getProductNameEn(arr) {
      return arr?.find((elm) => elm.language == "en")?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.margin {
  margin: 0 0 5px 5px;
}
.attachment-product {
  background-color: #dee4f8;
  color: #20419b;
  border-radius: 15px;
  cursor: pointer;
  gap: 0.25rem !important;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 5px 15px;
}
.dropdown {
  width: 100%;
}

.cs-card .card-body {
  padding: 8px;
}

.step::before {
  content: "" !important;
  width: 0;
  height: 0;
  border: none;
  margin-bottom: 0;
}

.step-icon {
  width: 34px;
  height: 34px;
  background-color: #fff;
  border: 2px solid #476dd6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;
  position: absolute;
  left: calc((100% - 36px) / 2);
}

.after-step {
  color: #808080;
  &::after {
    background-color: #808080;
  }
  .step-icon {
    border: 2px solid #808080;
  }
}
.step-active {
  color: #476dd6;
  &::after {
    background-color: #476dd6;
  }
}
</style>
